import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";

const Test2 = () => {
	return (
		<>
			<BodyClassName className="test">
				<Layout>
					<SEO title="Test" />
					<main className="page">
						<h1>Test 2</h1>
						<ins
							class="adsbygoogle"
							style={{ display: "inline-block", width: "1200px", height: "340px" }}
							data-ad-client="ca-pub-9026350790517056"
							data-ad-slot="8931067958"
						></ins>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

export default Test2;
